import styled from 'styled-components'
import { mobileBreakpoint } from 'react-components'

export const Container = styled.div`
    background-color: ${props => props.theme.colors.white};
    overflow: hidden;
`

export const InnerContainer = styled.div`
    max-width: 87.5rem;
    margin: 0 auto;

    @media only screen and (max-width: ${mobileBreakpoint.max}px) {
        margin-top: 9rem;
    }
`
